.dashboard-content {
  min-height: 89vh;
}
.top,
.down {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.top-left-card,
.top-right-card,
.down-left-card,
.down-right-card {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 33px;
  height: fit-content;
  background-color: white;
  border-radius: 8px;
}
.top-left-card {
  width: 40%;
}
.top-right-card {
  width: 59%;
}
.down-left-card {
  background: #e0d9fa;
  width: 66%;
}
.down-right-card {
  justify-content: space-between;
  min-height: 429px;
  width: 100%;
}
.down-orders {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  width: 100%;
  min-height: 429px;
  gap: 10px;
}
.recent-order {
  display: flex;
  padding: 10px;
  flex-direction: column;
  border-bottom: 1px solid #a6a8b1;
  gap: 10px;
}
.recent-order-child {
  display: flex;
  justify-content: space-between;
}

.dashselect {
  font-family: "Poppins";
  border: none;
  color: black;
  font-size: 12px;
}
.c-left-top {
  display: flex;
  justify-content: space-between;
}
.c-left-bottm {
  display: flex;
}
.c-down-bottm {
  justify-content: center;
  align-self: center;
}
.child {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.child-b {
  width: 50%;
}
.recent {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.s-heading {
  color: #8b8d97;
  font-size: 14px;
  font-family: "Inter";
}
.s-values {
  font-size: 20px;
  font-family: "Poppins";
  font-weight: 500;
}
.petprofile {
  display: flex;
  background-color: white;
  flex-wrap: wrap;
  padding: 10px;
  border-radius: 8px;
  width: 100%;
  gap: 10px;
}
.pet {
  flex: 4;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
}
.petinfo {
  width: 32%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
