.add-form {
  background: #fcfcfc;
  margin-top: 72px;
  margin-left: 255px;
  padding: 49px 15px;
}
.csvupload {
  color: #6b7280;
  padding: 5px 20px;
  border: 1px solid #e5e7eb;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
}
.mrs {
  margin-right: 5px;
}
.mts {
  margin-top: 5px;
}
.pointer {
  cursor: pointer;
}
/* form */
.adding-form {
  display: flex;
  flex-direction: column;
  gap: 13px;
}
.form-header {
  display: flex;
  gap: 10px;
  align-items: start;
  /* justify-content: space-between; */
}
.form-heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  max-width: 70%;
  font-size: 32px;
  line-height: 39px;
  color: #45464e;
}

.newresource-heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  max-width: 70%;
  font-size: 16px;
  color: #45464e;
}
.hidden {
  display: none;
}
.back-btn {
  padding: 5px 0px;
  width: 30px;
  height: 36px;
  border-radius: 8px;
  border: none;
}
.back-icon {
  height: 100%;
  width: 100%;
}
.btn-div {
  display: flex;
  gap: 20px;
}
.submit-btn {
  margin-left: auto;
  cursor: pointer;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #130f26;
  width: 161px;
  height: 36px;
  text-align: center;
  background: #e0d9fa;
  border: none;
  border-radius: 12px;
}

.addp {
  cursor: "pointer";
}
.storebtns {
  margin-left: auto;
  display: flex;
  gap: 20px;
}
.form-body {
  margin-top: 20px;
  display: flex;
  gap: 20px;
}
.form-content {
  flex: 2.2;
  background-color: white;
  display: flex;
  min-height: 500px;
  padding: 28px;
  gap: 40px;
  border-radius: 8px;
}
.form-small-headings {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #45464e;
}
.content-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.content-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.select-in {
  border-radius: 8px;
  font-size: 14px;
  width: 100%;
}
.form-row {
  display: flex;
  gap: 12px;
}
.half {
  flex-basis: 50%;
}
.field {
  background-color: rgba(239, 241, 249, 0.6);
  color: black;
  font-family: "Inter";
  font-size: 14px;
  width: 100%;
  min-height: 52px;
  border-radius: 8px;
  border: none;
  padding: 16px;
}
.popfield {
  background-color: rgba(239, 241, 249, 0.6);
  color: black;
  font-family: "Inter";
  font-size: 14px;
  width: 100%;
  height: 42px;
  border-radius: 8px;
  border: none;
  padding: 16px;
}
.popSelect {
  color: black;
  font-family: "Inter";
  font-size: 14px;
  width: 100%;
  border-radius: 8px;
  border: none;
}
.badge {
  color: #abafb1;
  background-color: white;
  height: 25px;
  width: fit-content;
  border-radius: 8px;
  border: 1px solid #abafb1;
  padding: 5px;
  font-size: 10px;
}
.field:focus {
  border: 1px solid skyblue;
  outline: 1px solid skyblue;
}
.description {
  min-height: 130px;
  /* text-align: start; */
}
.dis-date {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.dis-label {
  color: #53545c;
  font-weight: 500;
  font-size: 12px;
}
.form-headings {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #53545c;
}
.form-image {
  padding: 20px;
  flex: 0.8;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 12px;
  background-color: white;
  border-radius: 8px;
}
.image-sec {
  display: flex;
  flex-direction: column;
  /* text-align: center; */
  justify-content: space-around;
  border-radius: 12px;
  height: 250px;
  width: 100%;
  background-color: rgba(239, 241, 249, 0.6);
  padding: 15px;
}
.image-holder {
  /* margin-left: auto;
  margin-right: auto; */
  width: 80%;
  height: 80%;
  margin: 0 auto;
  padding: 16px;
}
.image {
  width: 80%;
  height: 100%;
  border-radius: 8px;
}
.small-image {
  display: flex;
  gap: 12px;
}
.image-sec-small {
  flex: 1;
  display: flex;
  /* gap: 10px; */
  flex-direction: column;
  text-align: center;
  justify-content: center;
  border-radius: 12px;
  height: 160px;
  width: 100%;
  background-color: rgba(239, 241, 249, 0.6);
}
.imageicon {
  height: 80%;
  padding: 20px;
}
.imagehead {
  margin-top: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
.file {
  display: none;
}
.imagepg {
  font-family: "Inter";
  font-style: normal;
  color: #8b8d97;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  padding-bottom: 16px;
}
.empty-image {
  flex: 1;
  border: 1px dashed black;
  border-radius: 12px;
  text-align: center;
  padding: 10px;
}
.form-footer {
  /* margin-left: 35px; */
  padding: 35px;
  border-radius: 12px;
  background-color: white;
}
.foot-left {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.foot-right {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.linked {
  /* float: right; */
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.linkedpop {
  /* float: right; */
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.link-field {
  width: 100%;
  max-width: 468px;
  margin-left: 31px;
}
.tax-fields {
  margin-left: 31px;
}
.tax-field {
  width: 228px;
}

/* resources form */
/* //////////////// */

.add-resource {
  height: 88vh;
  background: #fcfcfc;
}
.res-form {
  text-align: center;
  background: #fcfcfc;
  margin-top: 72px;
  margin-left: 255px;
  padding: 24px 47px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.res-no-more {
  display: flex;
}
.empty {
  flex: 1;
}
.more {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.more-btn {
  border: none;
  background: #fcfcfc;
  font-family: "Eudoxus Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #252f3f;
  text-decoration-line: underline;
}
.resform-content {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 0px 111px;
}
.res-row {
  display: flex;
  gap: 50px;
  text-align: start;
}
.res-field {
  flex: 1;
}
.res-input {
  background: #ffffff;
  font-size: 14px;
  width: 100%;
  height: 52px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 16px;
}
.res-sub-btn {
  width: 392px;
  height: 62px;
  background: #e0d9fa;
  border: none;
  border-radius: 8px;
}

/* Category-form */
/* /////////////// */
.popup {
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background: rgba(163, 163, 163, 0.5);
  z-index: 40;
}
.popup-heading {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #000000;
}
.popup-content {
  display: flex;
  flex-direction: column;
  padding: 28px 24px;
  gap: 28px;
  width: 488px;
  height: fit-content;
  background: #ffffff;
  border-radius: 12px;
}
.category-form {
  display: flex;
  flex-direction: column;
  padding: 0px 12px;
  gap: 36px;
  width: 400px;
}
.popup-header {
  display: flex;
  justify-content: space-between;
}
.icon-btn {
  background: #ffffff;
  border: none;
  cursor: pointer;
}
.cat-fields {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.form-btns {
  display: flex;
  gap: 40px;
}

.save-btn {
  width: 100%;
  padding: 17px 16px;
  gap: 10px;
  height: 58px;
  background: #e0d9fa;
  border: none;
  border-radius: 12px;
}
.cancel-btn {
  width: 100%;
  padding: 17px 16px;
  gap: 10px;
  height: 58px;
  background: #ffffff;
  border: 1px solid black;
  border-radius: 12px;
}

/* /////////Seller input//////////// */
/* ///////////////////////////////// */
.seller-inputs {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 24px;
}
.pop-inputs {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 24px;
}
.seller-input {
  padding: 12px 16px;
  height: 48px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
}
.seller-btns {
  margin-top: 16px;
  display: flex;
  gap: 36px;
}
.seller-btn {
  cursor: pointer;
  padding: 17px 16px;
  gap: 10px;
  width: 180px;
  height: 58px;
  background-color: white;
  border: 2px solid #e0d9fa;
  border-radius: 12px;
}
.seller-save {
  background-color: #e0d9fa;
}
.form-footer {
  width: 100%;
  display: flex;
  gap: 30px;
}
.seller-footer {
  display: flex;
  gap: 60px;
}

/* store form */
.sellerresources {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  width: auto;
  min-width: 351px;
}
.sellerresheader {
  display: flex;
  justify-content: space-between;
}
.sellerResheading {
  font-family: "Eudoxus Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #111827;
}
.chargesdiv {
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr 0.5fr;
  row-gap: 15px;
  column-gap: 10px;
  width: 100%;
}
.flexrow {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

/* store resources form */

.fields {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.btnpos {
  position: absolute;
  margin-left: 500px;
}

.table-pg {
  width: 100%;
  padding: 10px;
  background: #ffffff;
  border: 1px solid #e5e7eb;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  width: 100%;
  border-radius: 8px;
}
.tpg {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
  color: #8b8d97;
}
